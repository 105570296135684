import '../css/main.css';

import {MainMenu} from "./MainMenu";

export function Projects() {
    return (
        <div className="App">
            <div className="App-center">
                <h1>
                    More information on projects is coming soon.
                </h1>
            </div>
        </div>
    );
}
export default Projects();