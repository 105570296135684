import '../css/main.css';

export function About() {
    return (
        <div className="App">
            <div className="App-center">
                <div className="App-element">
                    <h2>Hi! I'm MageFurst.</h2>
                    <div className-="App-text">
                        <p>I am a recent college graduate and computer scientist who is interested in telling stories, and using an interactive medium to tell those stories.</p>
                        <p>I have a couple years of game and software development experience, but I want to create something of my very own.</p>
                        <p>I've got some ideas I'm really excited about, and I hope that what I make excites you too. It would make my day!</p>
                        <p>I hope you'll come along for the ride! There's a lot more to look forward to.</p>
                        <p>Thank you so much for reading! If you want more information on what we're working on currently, check out the website!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default About();