import '../css/main.css';

export function Contact() {
    return (
        <div className="App">
            <div className="App-center">
                <div className="App-element">
                    <h2>Contact Me</h2>
                    <p>The first step if you are experiencing an issue is to discuss it with the moderation team on Discord. Please do this before you attempt to contact me directly!</p>
                    <p>If you need to discuss something with me, please first send me an email as I prefer to discuss things through email.</p>
                    <p>If for some reason I do not respond via email, then, and only then, feel free to DM me on Discord. I do not check Discord DMs unless it is an emergency. <b>Please respect my privacy, thank you!</b></p>
                    <p>Email: admin@antimonydev.com</p>
                    <p>Discord: @magefurst</p>
                </div>
            </div>
        </div>
    );
}

export default Contact();