import '../css/MainMenu.css';

import AppBar from "@mui/material/AppBar";
import {IconButton, Toolbar, Box, Button, Typography, createTheme, ThemeProvider} from "@mui/material";
import logo from "../images/download.png";

//import {Router, Route, Link, RouteHandler} from 'react-router';

//import '@fontsource/roboto/300.css';

const navItems = ['about', 'projects', 'community','contact'];

const menu_typeface = createTheme({
    typography:{
        fontFamily:[
            'Consolas'
        ].join(','),
    },
    });
export function MainMenu() {
    return (
        <AppBar position="fixed">
            <Toolbar className= "MainMenu">
                <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{mr: 2}}
                    href="/"
                >
                    <img src={logo} className="Logo" alt="logo" />
                </IconButton>
                <Box className= "MenuItems" sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {navItems.map((item) => (
                        <Button
                            key={item}
                            sx={{ color: '#ffffff'}}
                            href={item} >
                            <ThemeProvider theme={menu_typeface}>
                                <Typography variant="h5" color="inherit" component="div">
                                    {item}
                                </Typography>
                            </ThemeProvider>
                        </Button>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
export default MainMenu();