import '../css/main.css';

import {MainMenu} from "./MainMenu";

export function NoPage() {
    return (
        <div className="App">
            <div className="App-center">
                <h1>
                    Page not found! Redirecting...
                </h1>
                <meta http-equiv="refresh" content="2; url=/"></meta>
            </div>
        </div>

    );
}
export default NoPage();