import '../css/main.css';
import '../css/Community.css'

import {MainMenu} from "./MainMenu";
import discord_logo from "../images/discordlogo.png";
import minecraft_logo from "../images/minecraftlogo.png";

export function Community() {
    return (
        <div className="App">

            <div className="App-center">
                <section class="SplitScreen">
                    <div id="discord-col">
                        <h2><img src={discord_logo} className="CommunityLogo" alt="logo" /></h2>
                        <h1>Discord Server</h1>
                        Our Official public Discord Server can be found <a href="https://discord.gg/RAf57BbgTA"> here</a>. If you're looking for the most up-to-date information on new projects, this is where to be! We also host events!
                    </div>
                    <div id="minecraft-col">
                        <h2><img src={minecraft_logo} className="CommunityLogo" alt="logo" /></h2>
                        <h1>Minecraft Server</h1>
                        <h4>mc.antimonydev.com</h4>
                        Our official version 1.20 Minecraft server can be accessed using the IP address above!
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Community();