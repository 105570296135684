import '../css/main.css';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./Home"
import {About} from "./About"
import {Projects} from "./Projects"
import {Community} from "./Community"
import {Contact} from "./Contact"
import {NoPage} from "./NoPage"
import {MainMenu} from "./MainMenu";
import {Footer} from "./Footer";
export default function App() {
    return (
        <BrowserRouter>
            <header className="App-header">
                <MainMenu />
            </header>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/projects' element={<Projects />} />
                <Route path='/community' element={<Community />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='*' element={<NoPage />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}
