import '../css/main.css';
import '../css/Homepage.css'

import logo from '../images/download.png'
export function Home() {
    return (
        <div className="App">
            <div className="App-center">
                <h1>
                    Antimony Development
                </h1>
                <h2>
                    <img src={logo} className="MainLogo" alt="logo" />
                </h2>
                <h2>Sole Proprietor Video Game Studio</h2>
            </div>
        </div>
    );
}
export default Home();